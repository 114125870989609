.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.arrow-btn {
  background-color: #eae3e3b8;
  /* color: red; */
  padding: 2px;
}
.arrow-btn:hover {
  background-color: #eae3e3b8;
  /* color: red; */
  padding: 2px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

video.videos-section {
  width: 15rem;
  height: 10rem;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

video.video-section-modal {
  max-height: 300px;
  width: 100%;
}

.modal-header {
  background-color: #ff7300 !important;
}
.video-gallery {
  border: 2px solid #ff7300;
  border-radius: 10px;
}

.video-playicon {
  height: 60px;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
  margin: 0 auto;
}
.pdf-viewer {
  height: 145px;
  width: 14rem;
  overflow: hidden;
  object-fit: cover;
}

#content html {
  height: 100%;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.pdf-heading {
  background: #ec710c;
  color: white;
  max-height: 45px;
  height: 46px;
  padding-top: 10px;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

@media (max-width: 1024px) {
  video.videos-section {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
