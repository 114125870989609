.footerdiv {
  position: fixed;
  bottom: 0px;
  width: 101vw;
  /* color: rgb(111, 78, 55); */
  /* margin-left: 10px; */
  z-index: 100;
  margin: 0px;
}

body {
  background-color: rgb(231, 218, 197);
  /* background-image: url("https://vcard.digitalesy.com/backend/img/vCards/IMG-628893612bf70-invst07.jpg.jpg");
    background-size: 1400px 800px; */
  font-family: "Poppins", sans-serif;
}
div::-webkit-scrollbar {
  display: none;
}
.firmname {
  font-family: 'Questrial', sans-serif;
  font-size: 22px!important;
  /* font-family: "Uniform Medium"; */
  margin: 10px 0;
  text-transform: uppercase;
  margin-top: 5rem !important;
}
.name {
  font-family: "Uniform";
  font-size: 20px;
  margin: auto;
}
.saraupshare{
  font-size: 20px!important;
}

.borderdiv {
  /* background:
    linear-gradient(to right, rgb(111, 78, 55) 6px, transparent 4px) 0 0,
    linear-gradient(to right, rgb(111, 78, 55) 6px, transparent 4px) 0 100%,
    linear-gradient(to left, rgb(111, 78, 55) 6px, transparent 4px) 100% 0,
    linear-gradient(to left, rgb(111, 78, 55) 6px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, rgb(111, 78, 55) 6px, transparent 4px) 0 0,
    linear-gradient(to bottom, rgb(111, 78, 55) 6px, transparent 4px) 100% 0,
    linear-gradient(to top, rgb(111, 78, 55) 6px, transparent 4px) 0 100%,
    linear-gradient(to top, rgb(111, 78, 55) 6px, transparent 4px) 100% 100%; */
  background-repeat: no-repeat;
  background-size: 60px 100px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #7c4e3e26;
  background-clip: padding-box;
  border: 1px solid #b3b5b6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.4rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

table,
tr,
td {
  margin-top: 5px;
}

td {
  height: 20px;
}
.buttonhome {
  width: 124px;
}

.css-d0uhtl {
  margin-left: 16px;
  background-color: black;
  color: white;
  padding: 5px;
  font-weight: bold;
}
.borderfeedback {
  /* border: 2px solid black; */
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 10px;
}

.buttonhomepagebuttonhomepage {
  border-radius: 22px;
  background-color: gray;
  border: 1px solid gray;
  font-size: small;
}

#txtPhone {
  width: 95%;
  border: none;
  padding: 0;
}
.homeankertag {
  color: white !important;
  text-decoration: none !important;
}
.footerankertag {
  /* color: rgb(111, 78, 55)!important; */
  text-decoration: none !important;
}
.homepagebutton {
  width: -webkit-fill-available;
}
.modal-dialog {
  position: relative;
  width: auto;
  top: 15%;
  transform: translate(-50%, -50%);
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.PhoneInputInput {
  border: none;
  border-bottom: 1px solid gray;
}
/* .rowcollection{
    padding-right: 0rem;
    padding-left: 0rem;
    --bs-gutter-x: 0px
} */
.cardImage {
  height: 100%;
  width: 100%;
  flex: 1;
  border-radius: 10px;
}

.flex-1 {
  flex: 1;
}
