.buttonmodal1{
    position: absolute;
    top: 50%;
    left: 0;
    /* border: 2px solid black; */
    border-radius: 100%;
}
.buttonmodal2{
    position: absolute;
    top: 50%;
    right: 0;
    /* border: 2px solid black; */
    border-radius: 100%;
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: none 
}
.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: none
   
}